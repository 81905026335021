import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const MenuBox = () => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexGrow={1}
            width={'100%'}
            maxWidth={'120px'}
            maxHeight={'120px'}
            border={"1px solid"}
            borderRadius={8}
            borderColor={theme.palette.outline.main}
            bgcolor={theme.palette.secondaryContainer.main}
            padding="16px"
            boxSizing="border-box"
            sx={{
                aspectRatio: '1/1',
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '140%',
                    color: theme.palette.text.primary,
                }}
                width={'100%'}
            >
                Editer mon menu
            </Typography>
        </Box>
    );
};

export default MenuBox;
