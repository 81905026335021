import React, { useContext } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OrdersContext } from '../../../context/OrdersContext';

const OrdersBox = () => {
    const theme = useTheme();
    const { orders, loading } = useContext(OrdersContext);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width={'100%'}
            maxWidth={'130px'}
            maxHeight={'130px'}
            border={"1px solid"}
            borderRadius={8}
            borderColor={theme.palette.outline.main}
            bgcolor={theme.palette.secondaryContainer.main}
            padding="16px"
            boxSizing="border-box"
            sx={{
                aspectRatio: '1/1'
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '140%',
                    color: theme.palette.text.primary,
                }}
            >
                Commandes en cours
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                marginTop="auto"
                width="100%"
                p={1}
            >
                {loading ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    <Typography
                        variant="h3"
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '48px',
                            lineHeight: '100%',
                            color: theme.palette.text.primary,
                        }}
                    >
                        {orders.length}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default OrdersBox;
