import { useTheme } from '@emotion/react';
import {
    Box,
    Button,
    Card, CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import '../../css/loader.css';
import useRatings from '../../hooks/useRatings';
import DateRangePicker from '../../shared-components/DateRangePicker';
import { Topbar } from '../../shared-components/Topbar';

ChartJS.register(ArcElement, Tooltip, Legend);

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} à ${hours}:${minutes}`;
}

function calculateAverageRating(ratings) {
    if (ratings.length === 0) return 0;
    const totalRatings = ratings.reduce((sum, rating) => sum + rating.rating, 0);
    return (totalRatings / ratings.length).toFixed(2);
}

function Ratings() {
    const theme = useTheme();
    const { ratings } = useRatings();
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('lastMonth');
    const [customRange, setCustomRange] = useState([null, null]);
    const [minRating, setMinRating] = useState(1);
    const [maxRating, setMaxRating] = useState(5);
    const [hasMessage, setHasMessage] = useState(false);
    const itemsPerPage = 5;

    // Combine all filters into a single useEffect hook
    useEffect(() => {
        setCurrentPage(1); // Reset to the first page whenever filters change
    }, [filter, customRange, minRating, maxRating, hasMessage]);

    if (!ratings) {
        return <div className="loader"></div>;
    }

    const filteredRatings = ratings.filter((rating) => {
        const ratingDate = dayjs(rating.createdDate);
        const now = dayjs();

        let dateFilter = true;
        switch (filter) {
            case 'lastDay':
                dateFilter = ratingDate.isAfter(now.subtract(1, 'day'));
                break;
            case 'last3Days':
                dateFilter = ratingDate.isAfter(now.subtract(3, 'day'));
                break;
            case 'lastWeek':
                dateFilter = ratingDate.isAfter(now.subtract(1, 'week'));
                break;
            case 'lastMonth':
                dateFilter = ratingDate.isAfter(now.subtract(1, 'month'));
                break;
            case 'custom':
                if (customRange[0] && customRange[1]) {
                    dateFilter = ratingDate.isAfter(dayjs(customRange[0])) && ratingDate.isBefore(dayjs(customRange[1]).add(1, 'day'));
                }
                break;
            default:
                dateFilter = true;
        }

        const ratingFilter = rating.rating >= minRating && rating.rating <= maxRating;
        const messageFilter = hasMessage ? rating.message && rating.message.trim() !== '' : true;

        return dateFilter && ratingFilter && messageFilter;
    });

    const ratingCounts = [1, 2, 3, 4, 5].map(
        (rating) => filteredRatings.filter((r) => r.rating === rating).length
    );

    const averageRating = calculateAverageRating(filteredRatings);

    const data = {
        labels: ['⭐', '⭐⭐', '⭐⭐⭐', '⭐⭐⭐⭐', '⭐⭐⭐⭐⭐'],
        datasets: [
            {
                label: 'Distribution des avis',
                data: ratingCounts,
                backgroundColor: [
                    theme.palette.primary.main,
                    theme.palette.primary.light,
                    theme.palette.primary.dark,
                    theme.palette.secondary.main,
                    theme.palette.error.light,
                ],
                borderColor: theme.palette.background.paper,
                borderWidth: 2,
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value} avis`;
                    }
                }
            }
        },
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentRatings = filteredRatings.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredRatings.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <Topbar name={'Avis'} showBackButton />
            <Box px={3} pb={3} sx={{ backgroundColor: theme.palette.background.default }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel>Filtrer par date</InputLabel>
                            <Select
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                label="Filtrer par date"
                            >
                                <MenuItem value="lastDay">Dernier jour</MenuItem>
                                <MenuItem value="last3Days">Derniers 3 jours</MenuItem>
                                <MenuItem value="lastWeek">Dernière semaine</MenuItem>
                                <MenuItem value="lastMonth">Dernier mois</MenuItem>
                                <MenuItem value="custom">Période personnalisée</MenuItem>
                            </Select>
                        </FormControl>

                        {filter === 'custom' && (
                            <DateRangePicker
                                startDate={customRange[0]}
                                endDate={customRange[1]}
                                onStartDateChange={(date) => setCustomRange([date, customRange[1]])}
                                onEndDateChange={(date) => setCustomRange([customRange[0], date])}
                            />
                        )}

                        <Box display={'flex'} justifyContent={'space-between'} gap={2} my={2}>
                            <TextField
                                type="number"
                                label="Note minimum"
                                value={minRating}
                                onChange={(e) => setMinRating(Number(e.target.value))}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                inputProps={{ min: 1, max: 5 }}
                            />

                            <TextField
                                type="number"
                                label="Note maximum"
                                value={maxRating}
                                onChange={(e) => setMaxRating(Number(e.target.value))}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                inputProps={{ min: 1, max: 5 }}
                            />
                        </Box>

                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel>Avis avec message</InputLabel>
                            <Select
                                value={hasMessage ? 'withMessage' : 'all'}
                                onChange={(e) => setHasMessage(e.target.value === 'withMessage')}
                                label="Avis avec message"
                            >
                                <MenuItem value="all">Tous les avis</MenuItem>
                                <MenuItem value="withMessage">Avec message</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {ratings.length === 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6" color="text.secondary" align="center" mt={4}>
                                Vous n'avez encore reçu aucun avis.
                            </Typography>
                        </Grid>
                    )
                    }
                    {ratings.length > 0 &&
                        (filteredRatings.length === 0 ? (
                            <Grid item xs={12}>
                                <Typography variant="h6" color="text.secondary" align="center" mt={4}>
                                    Aucun avis trouvé pour ces filtres.
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Box sx={{ padding: 2 }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Moyenne: {averageRating} / 5
                                        </Typography>
                                        <Pie data={data} options={options} />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2} sx={{ backgroundColor: theme.palette.background.paper, border: '1px solid ' + theme.palette.outline.main, borderRadius: '12px' }}>
                                        {currentRatings.map((rating) => (
                                            <Grid item xs={12} key={rating.id}>
                                                <Card sx={{ boxShadow: 'none' }}>
                                                    <CardContent sx={{ border: 'none' }}>
                                                        <Typography variant="body1">
                                                            {rating.rating} {rating.rating === 1 ? '⭐' : '⭐'.repeat(rating.rating)}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {formatDate(rating.createdDate)}
                                                        </Typography>
                                                        {rating.message && (
                                                            <Typography variant="body2" color="text.secondary" mt={1}>
                                                                {rating.message}
                                                            </Typography>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box display="flex" justifyContent="space-between" alignItems={'center'} mt={2}>
                                        <Button variant="contained" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                            Précédent
                                        </Button>
                                        <Typography variant="body2" color="text.secondary">
                                            Page {currentPage} sur {totalPages}
                                        </Typography>
                                        <Button variant="contained" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                            Suivant
                                        </Button>
                                    </Box>
                                    <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'} mt={2}>
                                        <Typography variant="body2" color="text.secondary">
                                            Nombre d'avis pour ce filtre: {filteredRatings.length}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ))
                    }
                </Grid>
            </Box>
        </>

    );
}

export default Ratings;
