import React from 'react';
import { Box, Container } from '@mui/material';
import BottomNavbar from './BottomNavbar';
import { OrdersProvider } from '../context/OrdersContext';

const MainLayout = ({ children }) => {
    return (
        <Container maxWidth="md" style={{ padding: '16px', fontFamily: '"Inter", sans-serif' }}>
            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    paddingBottom: '80px', // To ensure content is not hidden behind the navbar
                }}
            >
                {children}
            </Box>
            <OrdersProvider>
                <BottomNavbar />
            </OrdersProvider>
        </Container>
    );
};

export default MainLayout;
