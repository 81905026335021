import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Card, CardContent, Typography, Snackbar, Alert, Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import BackButton from "../../shared-components/BackButton";
import { useTheme } from "@emotion/react";
import { AppContext } from "../../context/AppContext";
import CategoryDialog from "../categories/components/CategoryDialog";
import Banner from "../../shared-components/Banner";
import { CategoryContext } from "../../context/CategoryContext";
import { EditOutlined } from "@mui/icons-material";
import { Topbar } from "../../shared-components/Topbar";
import CategoriesNav from "./components/CategoriesNav";
import FoodItemCard from "./components/FoodItemCard";
import useMenu from "../../hooks/useMenu";
import LongButton from "../../shared-components/LongButton";

const CategoryDetails = () => {
    const theme = useTheme();
    const { data, loading, currency, setCategory } = useContext(AppContext);
    const { categories, selectedCategory, setSelectedCategory, editCategory } = useContext(CategoryContext);
    const { menu } = useMenu();
    const [parent] = useAutoAnimate();
    const [tempMenuItems, setTempMenuItems] = useState([]);
    const [savePopup, showSavePopup] = useState(false);
    const [saveSuccessPopup, showSaveSuccessPopup] = useState(false);
    const navigate = useNavigate();
    const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState(false);
    const [editedCategory, setEditedCategory] = useState({ displayName: "", shortName: "" });

    useEffect(() => {
        if (selectedCategory) {
            setTempMenuItems(menu[selectedCategory.id] || []);
        }
    }, [menu, selectedCategory]);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setTempMenuItems(menu[category.id] || []);
    };

    const handleEditCategoryDialogOpen = () => {
        setEditCategoryDialogOpen(true);
        setEditedCategory(selectedCategory);
    };

    const handleEditCategoryDialogClose = () => {
        setEditCategoryDialogOpen(false);
    };

    const handleEditCategoryChange = (event) => {
        const { name, value } = event.target;
        setEditedCategory((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditCategory = async () => {
        await editCategory(selectedCategory.id, editedCategory);
        handleEditCategoryDialogClose();
        setSelectedCategory(editedCategory);
    };

    const swapItems = (array, i, j) => {
        [array[i], array[j]] = [array[j], array[i]];
    };

    const handlePullUp = (index) => (event) => {
        event.stopPropagation();
        if (index > 0 && index < tempMenuItems.length) {
            const updatedTempMenuItems = [...tempMenuItems];
            swapItems(updatedTempMenuItems, index, index - 1);
            setTempMenuItems(updatedTempMenuItems);
            showSavePopup(true);
        }
    };

    const handlePushDown = (index) => (event) => {
        event.stopPropagation();
        if (index >= 0 && index < tempMenuItems.length - 1) {
            const updatedTempMenuItems = [...tempMenuItems];
            swapItems(updatedTempMenuItems, index, index + 1);
            setTempMenuItems(updatedTempMenuItems);
            showSavePopup(true);
        }
    };

    const handleCancel = () => {
        setTempMenuItems(menu[selectedCategory.id] || []);
        showSavePopup(false);
    };

    const handleSave = async () => {
        await setCategory({
            [selectedCategory.id]: tempMenuItems,
        });
        showSavePopup(false);
        showSaveSuccessPopup(true);
    };

    if (loading || !categories) {
        return <div className="loader"></div>;
    }

    return (
        <Container maxWidth="md" disableGutters style={{ m: '0 !important', paddingY: '16px', fontFamily: '"Inter", sans-serif' }}>
            <Topbar name="Mon menu" showBackButton={true} />
            <Container
                sx={{
                    width: '100%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.default,
                    margin: 0,
                    paddingBottom: "10vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "100vh",
                }}
            >
                <Banner />
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <CategoriesNav
                            categories={categories}
                            onCategoryChange={handleCategoryChange}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '0 !important' }}>
                        <Typography variant="h6" className="mb-3 font-weight-bold" sx={{ display: "flex", justifyContent: "left" }}>
                            {selectedCategory?.displayName}
                        </Typography>
                        <Typography variant="subtitle1" className="mb-3 font-weight-bold" sx={{ display: "flex", justifyContent: "left" }}>
                            {selectedCategory?.shortName}
                        </Typography>
                        <LongButton onClick={handleEditCategoryDialogOpen} variant={"outlined"} icon={EditOutlined} label={"Renommer"} sx={{ maxWidth: '50%', height: "40px", borderRadius: '8px', marginTop: '10px' }} />
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Card sx={{
                                backgroundColor: theme.palette.primary.light,
                                px: 2,
                                py: 0,
                                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)"
                            }}>
                                <CardContent>
                                    <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} onClick={() => navigate('item/create', { state: { category: selectedCategory.id } })}>
                                        <Grid item sx={{
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <Avatar sx={{
                                                height: '2rem',
                                                width: '2rem',
                                                aspectRatio: 1 / 1,
                                                padding: '4px',
                                                background: theme.palette.primary.dark,
                                                borderColor: theme.palette.primary.dark,
                                                color: theme.palette.primary.contrastText,
                                                border: '1px solid'
                                            }}>
                                                <CIcon icon={cilPlus} />
                                            </Avatar>
                                        </Grid>
                                        <Grid item sx={{
                                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                        }}>
                                            <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                                                Ajouter un élément à la catégorie
                                                "<span style={{ fontWeight: 'bold' }}>{selectedCategory?.displayName}</span>"
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} ref={parent}>
                        {tempMenuItems.map((foodItem, i) => (
                            <FoodItemCard
                                key={foodItem.id}
                                id={foodItem.id}
                                category={selectedCategory.id}
                                name={foodItem.name}
                                description={foodItem.description}
                                order={foodItem.order}
                                price={foodItem.price}
                                otherSizes={foodItem.otherSizes ? JSON.stringify(foodItem.otherSizes) : null}
                                requiredChoices={foodItem.requiredChoices ? JSON.stringify(foodItem.requiredChoices) : null}
                                extras={foodItem.extras ? JSON.stringify(foodItem.extras) : null}
                                currency={currency}
                                photo={foodItem.photo}
                                video={foodItem.video}
                                thumbnail={foodItem.thumbnail}
                                newItem={foodItem.newItem}
                                popular={foodItem.popular}
                                outOfStock={foodItem.outOfStock}
                                tags={foodItem.tags}
                                pullUp={handlePullUp(i)}
                                pushDown={handlePushDown(i)}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Snackbar
                    open={savePopup}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message="Enregistrer les modifications?"
                    action={
                        <div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button color="primary" variant="outlined" size="small" onClick={handleSave}>
                                Enregistrer
                            </Button>
                            <Button color="warning" variant="outlined" size="small" onClick={handleCancel}>
                                Annuler
                            </Button>
                        </div>
                    }
                />
                <Snackbar
                    open={saveSuccessPopup}
                    autoHideDuration={5000}
                    onClose={() => showSaveSuccessPopup(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => showSaveSuccessPopup(false)} severity="success" variant="filled">
                        Catégories réorganisées avec succès
                    </Alert>
                </Snackbar>
                <CategoryDialog
                    open={editCategoryDialogOpen}
                    handleClose={handleEditCategoryDialogClose}
                    handleChange={handleEditCategoryChange}
                    handleSave={handleEditCategory}
                    newCategory={editedCategory}
                    editIndex={selectedCategory?.id}
                />
            </Container>
        </Container>
    );
};

export default React.memo(CategoryDetails);
