import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterChip from '../../../shared-components/FilterChip';

const OrderStatusFilterChips = ({ onFilterChange }) => {
    const [selectedChips, setSelectedChips] = useState({
        CREATED: true,
        CONFIRMED: true,
        VALIDATED: true,
        CLOSED: true,
    });

    const handleChipClick = (status) => {
        const updatedChips = {
            ...selectedChips,
            [status]: !selectedChips[status],
        };
        setSelectedChips(updatedChips);
        onFilterChange(updatedChips); // Notify parent of the filter change
    };

    const handleClearFilters = () => {
        const resetChips = {
            CREATED: true,
            CONFIRMED: true,
            VALIDATED: true,
            CLOSED: true,
        };
        setSelectedChips(resetChips);
        onFilterChange(resetChips); // Notify parent of the filter reset
    };

    return (
        <Box display="flex" flexDirection="row" alignItems="center" overflow={"scroll"} gap={1} width="80%">
            <IconButton onClick={handleClearFilters}>
                <ClearIcon />
            </IconButton>
            <FilterChip
                label="Ouvert"
                selected={selectedChips.CREATED}
                onClick={() => handleChipClick('CREATED')}
            />
            <FilterChip
                label="Confirmé"
                selected={selectedChips.CONFIRMED}
                onClick={() => handleChipClick('CONFIRMED')}
            />
            <FilterChip
                label="Validé"
                selected={selectedChips.VALIDATED}
                onClick={() => handleChipClick('VALIDATED')}
            />
            <FilterChip
                label="Fermé"
                selected={selectedChips.CLOSED}
                onClick={() => handleChipClick('CLOSED')}
            />
        </Box>
    );
};

export default OrderStatusFilterChips;
