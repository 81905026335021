import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Container, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import useApi from '../../../api';
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from '../../../context/AuthContext';
import useMenu from '../../../hooks/useMenu';
import DateRangePicker from '../../../shared-components/DateRangePicker';
import { format, isSameDay } from 'date-fns';
import { Topbar } from '../../../shared-components/Topbar';
import { ShoppingBagOutlined, TableBarOutlined } from '@mui/icons-material';
import OrderTypeFilterChips from '../components/OrderTypeFilterChips';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  return isSameDay(date, now) ? format(date, 'HH:mm') : format(date, 'dd/MM - HH:mm');
};

const ArchivedOrders = () => {
  const theme = useTheme();
  const { eateryId } = useContext(AuthContext);
  const { data } = useContext(AppContext);
  const { menu, getFoodItemById } = useMenu();
  const [flattenedMenuItems, setFlattenedMenuItems] = useState(null);
  const { apiCall } = useApi();
  const [archivedOrders, setArchivedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('lastDay');
  const [customRange, setCustomRange] = useState([null, null]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedFoodItem, setSelectedFoodItem] = useState(null); // State to hold selected food item for filtering
  const [selectedChips, setSelectedChips] = useState({
    IN_TABLE: true,
    TAKE_OUT: true,
  });

  useEffect(() => {
    const applyFilter = () => {
      const filtered = archivedOrders.filter(order =>
        selectedChips[order.orderType]
      );
      setFilteredOrders(filtered);
    };

    applyFilter();
  }, [archivedOrders, selectedChips]);

  const handleFilterChange = (newSelectedChips) => {
    setSelectedChips(newSelectedChips);
  };


  useEffect(() => {
    const fetchArchivedOrders = async () => {
      try {
        const response = await apiCall({
          url: `/admin/api/orders/${eateryId}`,
          method: 'GET',
        });
        setArchivedOrders(response); // Assuming the response is the array of archived orders
      } catch (error) {
        console.error('Failed to fetch archived orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedOrders();
  }, []);

  useEffect(() => {
    setFlattenedMenuItems(
      Object.values(menu).flatMap(categoryItems => categoryItems)
    );
  }, [menu]);

  useEffect(() => {
    filterOrders();
  }, [filter, customRange, archivedOrders, selectedFoodItem]);

  const filterOrders = () => {
    let filtered = [];
    const now = dayjs();

    switch (filter) {
      case 'lastDay':
        filtered = archivedOrders.filter(order => dayjs(order.dateCreated).isAfter(now.subtract(1, 'day')));
        break;
      case 'last3Days':
        filtered = archivedOrders.filter(order => dayjs(order.dateCreated).isAfter(now.subtract(3, 'day')));
        break;
      case 'lastWeek':
        filtered = archivedOrders.filter(order => dayjs(order.dateCreated).isAfter(now.subtract(1, 'week')));
        break;
      case 'lastMonth':
        filtered = archivedOrders.filter(order => dayjs(order.dateCreated).isAfter(now.subtract(1, 'month')));
        break;
      case 'custom':
        if (customRange[0] && customRange[1]) {
          filtered = archivedOrders.filter(order =>
            dayjs(order.dateCreated).isAfter(dayjs(customRange[0])) &&
            dayjs(order.dateCreated).isBefore(dayjs(customRange[1]).add(1, 'day'))
          );
        }
        break;
      default:
        filtered = archivedOrders;
    }

    // Filter by selected food item if any
    if (selectedFoodItem) {
      filtered = filtered.filter(order =>
        order.foodItems.some(foodItem => foodItem.id === selectedFoodItem.id)
      );
    }

    setFilteredOrders(filtered);
  };

  if (loading || !data || !Object.keys(menu).length) {
    return <div className='loader'></div>;
  }

  return (
    <Container maxWidth="sm" sx={{ padding: '16px', fontFamily: '"Inter", sans-serif', backgroundColor: 'background.default' }}>
      <Topbar name={'Commandes archivées'} showBackButton />
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Filtrer par date</InputLabel>
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filtrer par date"
        >
          <MenuItem value="lastDay">Dernier jour</MenuItem>
          <MenuItem value="last3Days">Derniers 3 jours</MenuItem>
          <MenuItem value="lastWeek">Dernière semaine</MenuItem>
          <MenuItem value="lastMonth">Dernier mois</MenuItem>
          <MenuItem value="custom">Période personnalisée</MenuItem>
        </Select>
      </FormControl>

      {filter === 'custom' && (
        <DateRangePicker
          startDate={customRange[0]}
          endDate={customRange[1]}
          onStartDateChange={(date) => setCustomRange([date, customRange[1]])}
          onEndDateChange={(date) => setCustomRange([customRange[0], date])}
        />
      )}

      <Autocomplete
        options={Object.values(flattenedMenuItems)} // Use menu as the options
        getOptionLabel={(option) => option.name} // Display the name of the food item
        value={selectedFoodItem}
        onChange={(event, newValue) => setSelectedFoodItem(newValue)}
        renderInput={(params) => <TextField {...params} label="Recherche par article" variant="outlined" fullWidth sx={{ marginBottom: 2 }} />}
      />

      <OrderTypeFilterChips onFilterChange={handleFilterChange} />

      {filteredOrders.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2 }}>
          Aucune commande archivée pour la période sélectionnée.
        </Typography>
      ) : (
        filteredOrders.map((order, index) => (
          <Card key={index} sx={{ marginBottom: '16px', backgroundColor: 'background.paper', border: '1px solid #000000', borderRadius: '12px' }}>
            <CardContent>
              <Typography component="p" variant="body2" sx={{ textAlign: 'right' }}>
                Création: {formatDate(order.dateCreated)}
              </Typography>
              <Typography component="p" variant="body2" sx={{ textAlign: 'right' }}>
                Fermeture: {formatDate(order.dateLastModified)}
              </Typography>
              <Typography variant="subtitle2" flex={1}>
                {data?.tables?.find(t => t.id == order.tableId)?.name
                  ? <Box display="flex" justifyContent="flex-start" alignItems="center"><TableBarOutlined />&nbsp;Table #{data?.tables?.find(t => t.id == order.tableId)?.name}</Box>
                  : <Box display="flex" justifyContent="flex-start" alignItems="center"><ShoppingBagOutlined />A emporter - {order.id.substring(order.id.length - 2)}</Box>}
              </Typography>
              <Box display="flex" flexDirection="column" mb={1}>
                {order.foodItems.map((foodItem, index) => (
                  <Typography key={index} component="p" variant="additionalNotes">
                    {foodItem.quantity}x {getFoodItemById(foodItem.id).name}
                  </Typography>
                ))}
              </Box>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box display="flex" flexDirection="row" alignItems="flex-start" mb={2} gap="4px" width="100%" height="24px">
                <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                  Sous-total
                </Typography>
                <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>
                  {order.foodItems.reduce((total, item) => total + item.totalPrice, 0)} {data.eatery.currency}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))
      )}
    </Container>
  );
};

export default ArchivedOrders;
