import { Box, Button, CardContent, Container, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { OrdersContext } from '../../context/OrdersContext';
import LongButton from '../../shared-components/LongButton';
import theme from '../../theme';
import AddTableButton from './components/AddTableButton';
import FilterChips from './components/FilterChips';
import TablesDragContext from './components/TablesDragContext';
import { Topbar } from '../../shared-components/Topbar';

const Tables = () => {
  const { user } = useContext(AuthContext);
  const { tables, createTable, reorderTables } = useContext(AppContext);
  const { orders } = useContext(OrdersContext);
  const [expandedTable, setExpandedTable] = useState(null);
  const [filteredStatuses, setFilteredStatuses] = useState({ FREE: true, PENDING: true, OCCUPIED: true, TO_VALIDATE: true });
  const [editMode, setEditMode] = useState(false);
  const [myTables, setMyTables] = useState([]);
  const [oldOrder, setOldOrder] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setMyTables(tables);
  }, [tables]);

  if (!tables || !myTables || !orders) {
    return <div className='loader'></div>;
  }

  const handleTableClick = (tableNumber) => {
    if (!editMode) {
      const table = tables.find(t => t.name === tableNumber);
      setExpandedTable((prev) => (prev === tableNumber ? null : tableNumber));
    }
  };

  const handleAddTable = (tableNumber) => createTable(tableNumber);

  const getTableStatus = (tableId) => {
    const tableOrder = orders.find(order => order.table?.id === tableId);
    if (tableOrder?.order?.status === 'CONFIRMED') return 'TO_VALIDATE';
    if (tableOrder?.order?.status === 'VALIDATED') return 'OCCUPIED';
    if (tableOrder?.order?.foodItems.length > 0) return 'PENDING';
    return 'FREE';
  };

  const handleFilterChange = (newFilters) => setFilteredStatuses(newFilters);

  const handleSetEditMode = () => {
    setExpandedTable(null);
    setEditMode((prev) => !prev);
  };

  const handleConfirmReorder = () => {
    reorderTables(myTables.map(t => t.id));
    setEditMode(false);
    setSnackbarOpen(false);
  };

  const handleCancelReorder = () => {
    setMyTables(oldOrder);
    setSnackbarOpen(false);
  };

  const filteredTables = myTables.filter(table => filteredStatuses[getTableStatus(table.id)]);

  return (
    <Container maxWidth="sm" style={{ padding: '16px', fontFamily: '"Inter", sans-serif' }}>
      <Topbar name="Mes tables" />
      {myTables.length === 0 ? (
        <Box sx={{ background: theme.palette.secondaryContainer.main }}>
          <CardContent sx={{ textAlign: 'center' }}>Vous n'avez encore créé aucune table.</CardContent>
        </Box>
      ) : (
        <Box>
          {!editMode && <FilterChips onFilterChange={handleFilterChange} />}
          {editMode && (
            <Box sx={{ background: theme.palette.secondaryContainer.main }}>
              <CardContent>Vous êtes en mode édition. Réordonnez les tables.</CardContent>
            </Box>
          )}
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap="10px"
            width="100%"
            height={'100%'}
            paddingY={2}
            marginY={5}
            sx={{
              background: editMode ? theme.palette.container.primary.highest : 'inherit',
              border: editMode ? '5px dashed ' + theme.palette.primary.main : 'none',
              borderRadius: editMode ? '12px' : '0',
              //borderColor: editMode ? theme.prale : 'inherit',

            }}
          >
            <TablesDragContext
              tables={filteredTables}
              setTables={setMyTables}
              orders={orders}
              editMode={editMode}
              expandedTable={expandedTable}
              handleTableClick={handleTableClick}
              getTableStatus={getTableStatus}
            />
            {user.role === "ADMIN" && !editMode && <AddTableButton onAddTable={handleAddTable} />}
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCancelReorder}
            message="Confirmer la nouvelle disposition des tables?"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={handleCancelReorder}>
                  Annuler
                </Button>
                <Button color="secondary" size="small" onClick={handleConfirmReorder}>
                  Confirmer
                </Button>
              </Box>
            }
          />
          {user.role === "ADMIN" && myTables.length > 0 && (
            <Box mx={6}>
              <LongButton
                variant="outlined"
                label={editMode ? 'Désactiver le mode édition' : 'Réordonner les tables'}
                onClick={handleSetEditMode}
                style={{ marginBottom: '16px' }}
              />
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Tables;
