import { Inventory2, Star } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import ContactPageIcon from '@mui/icons-material/ContactPageOutlined';
import { default as MenuIcon } from '@mui/icons-material/MenuBookOutlined';
import { Box, Container, CssBaseline, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { OrdersProvider } from '../../context/OrdersContext';
import "../../css/loader.css";
import LongButton from '../../shared-components/LongButton';
import MenuBox from './components/MenuBox';
import OrdersBox from './components/OrdersBox';
import TablesBox from './components/TablesBox';

export default function Dashboard3() {
    const { user, logout } = useContext(AuthContext);
    const { data } = useContext(AppContext);
    const theme = useTheme();

    if (!data) {
        return <div className="loader"></div>;
    }

    return (
        <Container
            maxWidth="md"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={2}
            sx={{ backgroundColor: theme.palette.background.default }}
        >
            <CssBaseline />
            <Stack spacing={1} sx={{ width: '100%', alignItems: 'center', flexGrow: 1 }}>
                {/* Title */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '20px 0px', width: '100%' }}>
                    <Typography sx={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '48px', lineHeight: '120%', letterSpacing: '-0.02em', color: theme.palette.text.primary, flexGrow: 1 }}>
                        {data.eatery.name}
                    </Typography>
                    <picture>
                        {/* Responsive logo */}
                        {data.eatery.theme.logo.jpg && <Box component="img" src={`data:image/jpeg;base64,${data.eatery.theme.logo}`} sx={{ width: '106px', marginTop: '-20px' }} />}
                    </picture>
                </Box>

                {/* Grid container with conditional rendering based on user role */}
                <Box sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridAutoRows: 'minmax(100px, auto)', gap: '16px' }}>
                    {/* TablesBox - Takes 2x1 */}
                    <Box sx={{ gridColumn: 'span 2', gridRow: 'span 1' }}>
                        <Link to="/tables" style={{ width: '100%', textDecoration: 'none' }}>
                            <TablesBox />
                        </Link>
                    </Box>

                    {/* Conditional OrderBox / MenuBox */}
                    {user.role === 'ADMIN' ? (
                        <>
                            {/* MenuBox for Admin (1x1) */}
                            <Box sx={{ gridColumn: 'span 1', gridRow: 'span 1', display: 'flex', justifyContent: 'center' }}>
                                <Link to="/categories" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <MenuBox />
                                </Link>
                            </Box>

                            {/* OrdersBox for Admin (1x1) */}
                            <Box sx={{ gridColumn: 'span 1', gridRow: 'span 1', display: 'flex', justifyContent: 'center' }}>
                                <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <OrdersProvider>
                                        <OrdersBox />
                                    </OrdersProvider>
                                </Link>
                            </Box>
                        </>
                    ) : (
                        /* OrdersBox for non-admin (2x1) */
                        <Box sx={{ gridColumn: 'span 1', gridRow: 'span 2', display: 'flex', justifyContent: 'center' }}>
                            <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none', display: 'flex', justifyContent: 'center' }}>
                                <OrdersProvider>
                                    <OrdersBox />
                                </OrdersProvider>
                            </Link>
                        </Box>
                    )}
                </Box>

                {/* Menu list */}

                {user.role === "ADMIN" &&
                    <Box xs={12} mt={3} padding={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '16px',
                                gap: '8px',
                                background: theme.palette.secondaryContainer.main,
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderRadius: '8px',
                                width: '100%',
                            }}
                        >
                            {user.role === "ADMIN" &&
                                <ListItem>
                                    <MenuIcon sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                                    <Link to="orders/archived" style={{ textDecoration: 'none' }}>
                                        <ListItemText primary="Voir mon menu" disableTypography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '14px', fontWeight: 600, color: theme.palette.text.secondary }} />
                                    </Link>
                                </ListItem>
                            }

                            {user.role === "ADMIN" &&
                                <ListItem>
                                    <ArchiveIcon sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                                    <Link to="orders/archived" style={{ textDecoration: 'none' }}>
                                        <ListItemText primary="Voir l'historique de mes commandes" disableTypography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '14px', fontWeight: 600, color: theme.palette.text.secondary }} />
                                    </Link>
                                </ListItem>
                            }


                            {user.role === "ADMIN" &&
                                <ListItem>
                                    <Star sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                                    <Link to="ratings" style={{ textDecoration: 'none' }}>
                                        <ListItemText primary="Voir les avis" disableTypography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '14px', fontWeight: 600, color: theme.palette.text.secondary }} />
                                    </Link>
                                </ListItem>
                            }

                            {user.role === "ADMIN" &&
                                <ListItem>
                                    <Inventory2 sx={{ marginRight: '10px', color: theme.palette.text.primary }} />
                                    <Link to="out-of-stock" style={{ textDecoration: 'none' }}>
                                        <ListItemText primary="Produits indisponibles" disableTypography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '14px', fontWeight: 600, color: theme.palette.text.secondary }} />
                                    </Link>
                                </ListItem>
                            }
                        </Box>
                    </Box>
                }

                {/* Logout button */}
                <Box mt={3} padding={2} width="100%">
                    <LongButton variant="outlined" onClick={logout} label={`Se déconnecter (${user.username})`} />
                </Box>

                {/* Footer */}
                <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: 'auto' }}>
                    {'Copyright © '}
                    <Link color="inherit" to="https://welcome.forknife.app/" style={{ textDecoration: 'none' }}>
                        Forknife
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Stack>
        </Container>
    );
}

