import { useAutoAnimate } from '@formkit/auto-animate/react';
import { AddOutlined } from '@mui/icons-material';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrdersContext } from '../../context/OrdersContext';
import '../../css/loader.css';
import useOrder from '../../hooks/useOrder';
import LongButton from '../../shared-components/LongButton';
import { Topbar } from '../../shared-components/Topbar';
import OrderCard from './components/OrderCard';
import OrderStatusFilterChips from './components/OrderStatusFilterChips';
import OrderTypeFilterChips from './components/OrderTypeFilterChips';
import "./css/animation.css";

const Orders2 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm')); // Detect screens that are at least 'sm'
  const { createOrder } = useContext(OrdersContext);
  const { orders } = useOrder();
  const navigate = useNavigate();
  const [parent] = useAutoAnimate();
  // State for type filters
  const [selectedTypeChips, setSelectedTypeChips] = useState({
    IN_TABLE: true,
    TAKE_OUT: true,
  });

  // State for status filters
  const [selectedStatusChips, setSelectedStatusChips] = useState({
    CREATED: true,
    CONFIRMED: true,
    VALIDATED: true,
    CLOSED: true,
  });

  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const applyFilter = () => {
      const filtered = orders.filter(order =>
        selectedTypeChips[order.order.orderType] && selectedStatusChips[order.order.status]
      );
      setFilteredOrders(filtered);
    };

    applyFilter();
  }, [orders, selectedTypeChips, selectedStatusChips]);

  // Handle type filter change
  const handleTypeFilterChange = (newSelectedChips) => {
    setSelectedTypeChips(newSelectedChips);
  };

  // Handle status filter change
  const handleStatusFilterChange = (newSelectedChips) => {
    setSelectedStatusChips(newSelectedChips);
  };

  const handleCreateNewOrderClick = async () => {
    const newOrder = await createOrder('TAKE_OUT');
    navigate('/orders/edit', { state: { currentOrder: newOrder } });
  };

  // Split orders based on tableId
  const tableOrders = filteredOrders.filter(order => order.table); // Orders with tableId
  const noTableOrders = filteredOrders.filter(order => !order.table); // Orders without tableId

  if (!orders) {
    return <div className='loader'></div>;
  }

  return (
    <>
      <Topbar name="Mes commandes" showBackButton={false} />
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          padding: theme.spacing(2),
          height: '90%',
          fontFamily: theme.typography.fontFamily,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid container px={5}>
          <Grid item style={{ display: "flex", alignItems: "center", width: '100%' }} mb={2}>
            <Typography>Commande:</Typography>
            <OrderTypeFilterChips onFilterChange={handleTypeFilterChange} />
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center", width: '100%' }} mb={2}>
            <Typography>Statut:</Typography>
            <OrderStatusFilterChips onFilterChange={handleStatusFilterChange} />
          </Grid>
        </Grid>

        {filteredOrders.length === 0 ? (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.spacing(2),
                border: `1px solid ${theme.palette.outline.main}`,
                borderRadius: '12px',
                backgroundColor: theme.palette.container.primary.highest,
                height: '480px'
              }}
            >
              <Typography variant="h6" textAlign={'center'} mb={1} gutterBottom sx={{ color: theme.palette.text.primary }}>
                Aucune commande en cours.
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: theme.spacing(2), color: theme.palette.text.secondary }}>
                Vos bons de commande s’afficheront sur cet écran dès qu’un client l’aura envoyée depuis son téléphone.
              </Typography>
              <LongButton
                variant="primary"
                icon={AddOutlined}
                label="Nouvelle commande à emporter"
                sx={{ marginBottom: theme.spacing(2) }}
                onClick={handleCreateNewOrderClick}
              />
              <LongButton
                variant="outlined"
                icon={ArchiveOutlined}
                label="Voir les commandes archivées"
                sx={{ marginBottom: theme.spacing(2) }}
                onClick={() => navigate('/orders/archived')}
              />
            </Box>
          </Box>
        ) : (
          <Grid container my={3} ref={parent} justifyContent={"space-evenly"}>
            {isSmallScreen ? (
              // Split into two columns for larger screens
              <>
                <Grid item pl={0} xs={12} sm={5}>
                  {tableOrders.map((order, index) => (
                    <OrderCard
                      key={index}
                      order={order.order}
                      table={order.table}
                    />
                  ))}
                </Grid>
                <Grid item pl={0} xs={12} sm={5}>
                  {noTableOrders.map((order, index) => (
                    <OrderCard
                      key={index}
                      order={order.order}
                      table={order.table}
                    />
                  ))}
                </Grid>
              </>
            ) : (
              // Display all in a single column for small screens
              <Grid item pl={0} xs={12}>
                {filteredOrders.map((order, index) => (
                  <OrderCard
                    key={index}
                    order={order.order}
                    table={order.table}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Orders2;
